<template>
  <StatTotal />
</template>

<script>
import StatTotal from "../components/stat/StatTotal";

export default {
  name: 'Home',
  components: {
    StatTotal,
  }
}
</script>