<template>
  <v-card
      elevation="11"
      outlined
      :loading="loading"
  >
    <v-card-title>Статистика</v-card-title>

    <v-simple-table
        v-if="!loading && !error"
        dense
    >
      <template v-slot:default>
        <tbody>
        <tr>
          <td>Даты</td>
          <td>с {{ statData.date.min | fmtDateShort }} по {{ statData.date.max | fmtDateShort }}</td>
        </tr>
        <tr>
          <td>Всего уникальных заправок</td>
          <td>{{ statData.station.count }}</td>
        </tr>
        <tr><th colspan="2">Топливо</th></tr>
        <tr>
          <td>Всего заправлено</td>
          <td>{{ statData.volume.sum | fmthundred }} л.</td>
        </tr>
        <tr>
          <td>В среднем заправлено</td>
          <td>{{ statData.volume.avg | fmthundred }} л.</td>
        </tr>
        <tr><th colspan="2">Стоимость</th></tr>
        <tr>
          <td>В среднем за литр</td>
          <td>{{ statData.costPerLiter.avg | fmthundred }} р.</td>
        </tr>
        <tr>
          <td>Диапазон за литр</td>
          <td> от {{ statData.costPerLiter.min | fmthundred }} до {{ statData.costPerLiter.max | fmthundred }} р.</td>
        </tr>
        <tr>
          <td>Всего потрачено</td>
          <td> {{ statData.totalCost.sum | fmthundred }} р.</td>
        </tr>
        <tr>
          <td>В среднем за заправку</td>
          <td> {{ statData.totalCost.avg | fmthundred }} р.</td>
        </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
</template>

<script>
import statApi from '../../api/stat.js'

export default {
  name: 'StatTotal',
  data: () => ({
    loading: true,
    error: false,
    statData: {},
  }),
  watch: {
    options: {
      handler () {
        this.getDataFromApi()
      },
      deep: true,
    },
  },
  mounted () {
    this.getDataFromApi()
  },
  methods: {
    getDataFromApi () {
      this.loading = true

      statApi.getTotal().then(response => {
        this.statData = response.data.stat
        this.error = false
      }).catch(() => {
        this.statData = {}
        this.error = true
      }).finally(() => this.loading = false)
    },
  },
}
</script>