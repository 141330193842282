import {httpClient} from './httpClient';

const BASE_END_POINT = '/api/v1/stat';

const getAgg = (dateFrom, dateTo) => {
    let params = {}

    if (dateFrom !== null) {
        params.dateFrom = dateFrom;
    }

    if (dateTo !== null) {
        params.dateTo = dateTo;
    }

    return httpClient.get(BASE_END_POINT + '/agg', {params})
}

const getStations = (dateFrom, dateTo) => {
    let params = {}

    if (dateFrom !== null) {
        params.dateFrom = dateFrom;
    }

    if (dateTo !== null) {
        params.dateTo = dateTo;
    }

    return httpClient.get(BASE_END_POINT + '/station', {params})
}

const getMileageStat = () => {
    return httpClient.get(BASE_END_POINT + '/mileage')
}

const getCostPerLiterStat = () => {
    return httpClient.get(BASE_END_POINT + '/cost-per-piter')
}

const getTotal = () => {
    return httpClient.get(BASE_END_POINT + '/total')
}

export default {
    getAgg,
    getStations,
    getTotal,
    getMileageStat,
    getCostPerLiterStat,
}